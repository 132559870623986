import { HTTP } from "@/service/axios";

export default {
  FETCH_ASSESSMENT_LIST: async ({ commit }, obj) => {
    HTTP.defaults.headers.common.Authorization = `Bearer ${obj.token}`;
    await HTTP.get(`assessment`)
      .then((res) => {
        if (res.data.success) {
          commit("SET_ASSESSMENT_LIST", res.data.obj);
        }
      })
      .catch((e) => {
        console.log("FETCH_ASSESSMENT_LIST error", e.response);
      });
  },
  FETCH_CATEGORY_LIST: async ({ commit }, obj) => {
    HTTP.defaults.headers.common.Authorization = `Bearer ${obj.token}`;
    let res = await HTTP.get(`category`).catch((e) => {
      console.log("FETCH_CATEGORY_LIST error", e.response);
    });
    if (res.data.success) {
      commit("SET_CATEGORY_LIST", res.data.obj);
    }
  },
  FETCH_QUESTION_LIST: async ({ commit }, obj) => {
    HTTP.defaults.headers.common.Authorization = `Bearer ${obj.token}`;
    let res = await HTTP.get(`question`).catch((e) => {
      console.log("FETCH_QUESTION_LIST error", e.response);
    });
    if (res.data.success) {
      commit("SET_QUESTION_LIST", res.data.obj);
    }
  },
  FETCH_OPEN_MENU: async ({ commit }, obj) => {
    HTTP.defaults.headers.common.Authorization = `Bearer ${obj.token}`;
    await HTTP.get(`close/function`)
      .then((res) => {
        if (res.data.success) {
          let status = res.data.obj.status == 1 ? true : false;
          commit("SET_OPEN_MENU", status);
        }
      })
      .catch((e) => {
        console.log("FETCH_OPEN_MENU error", e.response);
      });
  },
};
