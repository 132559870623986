import Vue from 'vue'
import Vuex from 'vuex'
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import createPersistedState from "vuex-persistedstate";
import initState from "./initState";

Vue.use( Vuex )

const plugins = [
  createPersistedState({
    key: "mana-dev",
  }),
];

export default new Vuex.Store({
  state: initState(),
  getters,
  mutations,
  actions,
  plugins,
})
