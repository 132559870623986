import axios from "axios";

export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 2000 * 60,
});

export const HTTPCFOE = axios.create({
  baseURL: process.env.VUE_APP_CFOE_BASE_URL,
  timeout: 2000 * 60,
});