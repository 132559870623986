// @ts-nocheck
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/sign-in',
    name: 'Sign in',
    component: () => import('@/views/SignIn.vue')
  },
  {
    path: '/console',
    name: 'Console',
    component: () => import('@/views/Console/Console.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
