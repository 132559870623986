import initState from "./initState";

export default {
  SET_USER_OBJECT: (state, payload) => {
    state.user[payload.key] = payload.value;
  },
  SIGNOUT: (state) => {
    const initial = initState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
  SET_USER: async (state, payload) => {
    state.user = payload;
  },
  SET_TOKEN: (state, payload) => {
    state.token = payload;
  },
};
